import React, { useState } from "react";
import css from './SectionFAQ.module.css';
import NamedLink from "../NamedLink/NamedLink";
import IconCard from "../SavedCardDetails/IconCard/IconCard";

const SectionFAQs = (props) => {
  const { section } = props;
  const { description, title, blocks, callToAction } = section || {};

  const [showFaqBox, setShowFaqBox] = useState(false);

  return(
    <div className={css.root}>
      <h2 className={css.title}>
        Players & Parents, Have <span className={css.colored}>Questions?</span>
      </h2>
      <div className={css.faqContainer}>
        {blocks?.map((block, index) => {
          const {title, text, blockName} = block;
          return(
            <div key={index} className={css.faqWrapper}>
              <div className={css.faqItem} onClick={() => setShowFaqBox(showFaqBox === blockName ? false : blockName)}>
                <span>{showFaqBox === blockName ? '-' : '+'}</span>
                <p>{title.content}</p>
              </div>
              {showFaqBox === blockName && (
                <div className={css.content}>{text.content}</div>
              )}
            </div>
          )
        })}
      </div>
      <div className={css.bookButtonWrapper}>
        <NamedLink name='SearchPage' className={css.ctaButton}>
          {callToAction.content}
          <span className={css.arrow}>→</span>
        </NamedLink>
      </div>
    </div>
  )
}

export default SectionFAQs