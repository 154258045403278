import React from 'react';
import css from './SectionTrendingListings.module.css';
import classNames from 'classnames';
import NamedLink from '../NamedLink/NamedLink';

const SectionTrendingListings = (props) => {
  const {section, history} = props;
  const {description, title, blocks} = section || {};
  const lastBlock = blocks.slice(19, 20)[0];

  function handleRedirect(path){
    history.push(path)
  }

  const multipliedBlock = [...blocks.slice(0, 19), ...blocks.slice(0, 19), ...blocks.slice(0, 19)]

  return(
    <section className={css.root}>
      <div className={css.descriptionContent}>
        <h2 className={css.title}>{title.content}</h2>
        <h2 className={css.description}>{description.content}</h2>
      </div>
      <div className={css.gridContainer}>
        <div className={classNames(css.firstContainer, css.container)}>
          {blocks.length > 0 && multipliedBlock?.map((block, index) => {
            const {media, text, title, callToAction} = block;
            const imageUrl = media?.image?.attributes?.variants?.['square400']?.url;

            return(
              <div className={css.card} key={`${index}-${title.content}`} onClick={() => handleRedirect(callToAction.href)}> 
                <img src={imageUrl} alt={title.content} />
                <div className={css.details}>
                  <h3>{title.content}</h3>
                  <div className={css.cardDescription}>
                    <p>{text.content}</p>
                    <div className={css.arrow}>→</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className={classNames(css.secondContainer, css.container)}>
          {blocks.length > 0 && multipliedBlock.reverse().map((block, index) => {
            const {media, text, title, callToAction} = block;
            const imageUrl = media?.image?.attributes?.variants?.['square400']?.url;

            return(
              <div className={css.card} key={`${index}-${title.content}`} onClick={() => handleRedirect(callToAction.href)}> 
                <img src={imageUrl} alt={title.content} />
                <div className={css.details}>
                  <h3>{title.content}</h3>
                  <div className={css.cardDescription}>
                    <p>{text.content}</p>
                    <div className={css.arrow}>→</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className={css.bottomSectionWrapper}>
        <h2><span className={css.greenBackgroundText}>Join for free</span> to see playerintro's complete list of influential <span className={css.greenBackgroundText}>coaches and experts</span> available for booking</h2>
        <NamedLink className={css.seeAllButton} name='SearchPage'>
          {lastBlock.callToAction.content}
          <span className={css.arrow}>→</span>
        </NamedLink>
      </div>
    </section>
  )
}

export default SectionTrendingListings