import React from "react";
import css from './SectionSupporters.module.css';

const SectionSupporters = (props) => {
  const { section } = props;
  const { description, title, blocks, callToAction } = section || {};

  return(
    <div className={css.root}>
      <h3 className={css.title}>{title.content}</h3>
      <div className={css.iconWrapper}>
        {blocks.length > 0 && blocks.map((block, index) => {
          const { media } = block;
          const imageUrl = media?.image?.attributes?.variants?.['original400']?.url;

          return (
            <img src={imageUrl} alt={media.alt} key={`${index}`}/>
          )
        })}
      </div>
    </div>
  )
}

export default SectionSupporters