import React from "react";
import css from './SectionFeature.module.css';
import NamedLink from "../NamedLink/NamedLink";

const SectionFeature = (props) => {
  const { section } = props;
  const { description, title, blocks, callToAction } = section || {};

  return(
    <div className={css.root}>
      <h2 className={css.title}>
        It's all about&nbsp;
        <span className={css.colored}>who you know.</span>
      </h2>
      <p className={css.description}>{description.content}</p>
      <div className={css.container}>
        {blocks.length > 0 && blocks.map((block, index) => {
          const { media, text, title } = block;
          const imageUrl = media?.image?.attributes?.variants?.['square400']?.url;

          return (
            <div className={css.card} key={`${index}-${title.content}`}>
              <img src={imageUrl} alt={title.content} />
              <h3>{title.content}</h3>
              <p>{text.content}</p>
            </div>
          )
        })}
      </div>
      <div className={css.bookButtonWrapper}>
        <NamedLink name='SearchPage' className={css.ctaButton}>
          {callToAction.content}
          <span className={css.arrow}>→</span>
        </NamedLink>
      </div>
    </div>
  )
}

export default SectionFeature