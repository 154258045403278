import React from "react";
import css from './SectionTestimonials.module.css';
import { Slider } from "../../containers/Slider/Slider";


const SectionTestimonials = (props) => {
  const { section } = props;
  const { description, title, blocks } = section || {};

  return (
    <div className={css.root}>
      <h2 className={css.title}>{title.content}</h2>
      <div className={css.tagsContainer}>
        <span>Great</span>
        <span>Insightful</span>
        <span>Fantastic</span>
        <span>Informative</span>
        <span>Incredible</span>
        <span>Unique</span>
        <span>Experienced</span>
        <span>Awesome!</span>
        <span>Impactful</span>
        <span>Exceptional</span>
      </div>
      <Slider blocks={blocks}/>
    </div>
  )
}

export default SectionTestimonials