import React, { useEffect, useState } from "react";
import css from './SectionStatics.module.css';
import classNames from "classnames";
import { useInView } from "react-intersection-observer";

const ProgressCircle = (props) => {
  const { count, text, percent, inView, countLabel } = props;
  const [progressValue, setProgressValue] = useState(0);
  const [countValue, setCountValue] = useState(0);

  const progressStyle = {
    background: `conic-gradient(#9238FF ${3.6 * progressValue}deg, #fff 0deg)`,
  };

  let countInterval, percentInterval;

  const handleProgressEffect = () => {    
    percentInterval = setInterval(() => {
      setProgressValue((prev) => {
        if (prev >= percent) {
          clearInterval(percentInterval);
          return percent;
        }
        return prev + percent / 100;
      });
    }, 20);

    countInterval = setInterval(() => {
      setCountValue((prev) => {
        if (prev >= count) {
          clearInterval(countInterval);
          return count;
        }
        return prev + count / 100; 
      });
    }, 20);
  };

  useEffect(() => {
    if(inView) handleProgressEffect()
    else {
      setProgressValue(0)
      setCountValue(0)
    }
    
    return () => {
      clearInterval(percentInterval);
      clearInterval(countInterval);
    };
  }, [percent, inView]);

  return (
    <div className={classNames(css.circle, css.borderBackground)} style={progressStyle}>
      <h3>{countLabel}</h3>
      <p>{text}</p>
    </div>
  );
};

const SectionStatics = (props) => {
  const { section, history } = props;
  const { description, title, blocks, callToAction } = section || {};

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  return(
    <div className={css.root}>
      <div className={css.descriptionWrapper}>
        <h2 className={css.title}>{title.content}</h2>
        <p className={css.description}>{description.content}</p>
      </div>
      <div className={css.container} ref={ref}>
        <ProgressCircle
          inView={inView}
          count='1500'
          text='Youth Sports Participants Annually'
          percent={70}
          countLabel='60M+'
        />
        <ProgressCircle
          inView={inView}
          count='1500'
          text='in NCAA Scholarships Available Each Year'
          percent={90}
          countLabel='$2.7B'
        />
        <ProgressCircle
          inView={inView}
          count='500'
          text='of Parents Struggle to Navigate Youth Sports'
          percent={50}
          countLabel='50%+'
        />
        <ProgressCircle
          inView={inView}
          count='2100'
          text='Higher Chance of Advancement With Better Access to Information'
          percent={30}
          countLabel='30%'
        />
      </div>
      <p className={css.subTitle}>
        This is our solution for the next generation of <span>elite athletes.</span>
      </p>
      <div className={css.bookButtonWrapper}>
        <div className={css.ctaButton} onClick={() => history.push(callToAction.href)}>
          {callToAction.content}
          <span className={css.arrow}>→</span>
        </div>
      </div>
    </div>
  )
}

export default SectionStatics