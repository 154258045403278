import React from "react";
import css from './SectionHowItWorks.module.css';
import NamedLink from "../NamedLink/NamedLink";
import classNames from "classnames";

const SectionHowItWorks = (props) => {
  const { section, history } = props;
  const { description, title, blocks, callToAction } = section || {};

  return(
    <div className={css.root}>
      <h2 className={css.title}>
        Creating Relationships Through <span>Player-initiated</span> Conversation.
      </h2>
      <p className={css.description}>{description.content}</p>
      <div className={css.container}>
        {blocks.length > 0 && blocks.map((block, index) => {
          const { media, text, title } = block;
          const imageUrl = media?.image?.attributes?.variants?.['original400']?.url;

          return (
            <div className={css.card} key={`${index}-${title.content}`}>
              <img src={imageUrl} alt={title.content} className={classNames(index === 0 && css.searchIcon)}/>
              <h3>{title.content}</h3>
              <p>{text.content}</p>
            </div>
          )
        })}
      </div>
      <div className={css.bookButtonWrapper}>
        <div className={css.ctaButton} onClick={() => history.push(callToAction.href)}>
          {callToAction.content}
          <span className={css.arrow}>→</span>
        </div>
      </div>
    </div>
  )
}

export default SectionHowItWorks