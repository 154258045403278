import React from "react";
import css from './SectionHeros.module.css';
import NamedLink from "../NamedLink/NamedLink";

const SectionHeros = (props) => {
  const { section, history } = props;
  const { description, title, callToAction } = section || {};

  return(
    <div className={css.root}>
      <h2 className={css.title}>{title.content}</h2>
      <p className={css.description}>{description.content}</p>
      <div className={css.bookButtonWrapper}>
        <div className={css.ctaButton} onClick={() => history.push(callToAction.href)}>
          {callToAction.content}
          <span className={css.arrow}>→</span>
        </div>
      </div>
    </div>
  )
}

export default SectionHeros