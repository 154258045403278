import React, { useState, useEffect } from 'react';
import './Slider.css';

const Item = ({ item, index }) => {
  const { media, text, title } = item;
  const imageUrl = media?.image?.attributes?.variants?.['square400']?.url;
  return (
    <div key={index} className="slider-item">
      <img src={imageUrl} alt={title.content} />
      <p>{text.content}</p>
      <h3>{title.content}</h3>
      <h4>{media.alt}</h4>
    </div>
  );
};

export const Slider = ({ blocks }) => {
  const items = blocks;

  const itemWidth =
    typeof window !== 'undefined' &&
    window.document?.querySelector('.slider-item')?.offsetWidth;

  const [activeIndex, setActiveIndex] = useState(1); // Start at 1 for seamless looping
  const [isTransitioning, setIsTransitioning] = useState(false);

  const totalItems = items.length;

  const nextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveIndex(activeIndex + 1);
    }
  };

  const prevSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveIndex(activeIndex === 1 ? totalItems : activeIndex - 1);
    }
  };

  useEffect(() => {
    if (isTransitioning) {
      const handleTransitionEnd = () => {
        setIsTransitioning(false);

        if (activeIndex === 0) {
          setActiveIndex(totalItems);
        } else if (activeIndex === totalItems + 1) {
          setActiveIndex(1);
        }
      };

      const sliderTrack = document.querySelector('.slider-track');
      sliderTrack.addEventListener('transitionend', handleTransitionEnd);

      return () => {
        sliderTrack.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [activeIndex, isTransitioning, totalItems]);

  const dynamicWidthToReduce = itemWidth > 400 ? itemWidth / 2 : 10;

  return (
    <div className="slider">
      <div className="slider-container">
        <div
          className="slider-track"
          style={{
            transform: `translateX(-${activeIndex * (itemWidth + 30) -
              dynamicWidthToReduce}px)`,
            transition: isTransitioning ? 'transform 0.5s ease' : 'none',
          }}
        >
          {items.slice(items.length - 1, items.length).map((item, index) => (
            <Item item={item} index={index} />
          ))}

          {items.map((item, index) => (
            <Item item={item} index={index} />
          ))}

          {items.slice(0, 1).map((item, index) => (
            <Item item={item} index={index} />
          ))}
        </div>
      </div>
      <div className="arrowWrapper">
        <button className="leftArrow" onClick={prevSlide}>
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8574 7L1.00028 7"
              stroke="#19191A"
              stroke-width="1.5"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
            <path
              d="M6 2L1 7L6 12"
              stroke="#19191A"
              stroke-width="1.5"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button className="rightArrow" onClick={nextSlide}>
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 7L13.8571 7"
              stroke="#19191A"
              stroke-width="1.5"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
            <path
              d="M8.85742 2L13.8574 7L8.85742 12"
              stroke="#19191A"
              stroke-width="1.5"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
